import { lighten } from "../../../utils/sassFunction";

export const colors = [
  { name: "primary", title: "Primario", value: "#00a0ce" },
  { name: "primaryHover", title: "Primario alternativo", value: "#015872" },
  { name: "secondary", title: "Secundario", value: "#47bbb0" },
  { name: "success", title: "Verde", value: "#19a85b" },
  { name: "vibrant", title: "Vivo", value: "#faac5b" },
  { name: "grey", title: "Gris", value: "#b8b8b8" },
  { name: "info", title: "Info", value: "#4daac8" },
];

export const colorsalert = [
  { name: "light", title: "Default", value: `${lighten("#6f6f6f", 48)}` },
  { name: "success", title: "Suceso", value: "#19a85b" },
  { name: "warning", title: "Alerta", value: "#fbbc04" },
  { name: "danger", title: "Peligro", value: "#b9000b" },
];
